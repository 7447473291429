import React from 'react'
import { Mapbox } from '@digitalworkflow/dwreactcommon'

const MapboxPage = () => {
  return (
    <div>
      <Mapbox
        points={[
          {
            lng: -79.38,
            lat: 43.67,
            icon: 'home',
            label: 'buling 1',
            id: 'test_id_1'
          },
          {
            lng: -79.37,
            lat: 43.65,
            icon: 'home',
            label: 'buling 2',
            id: 'test_id_2'
          },
          {
            lng: -79.39,
            lat: 43.63,
            icon: 'home',
            label: 'buling 3',
            id: 'test_id_3'
          }
        ]}
        height={600}
      />
    </div>
  )
}

export default MapboxPage
