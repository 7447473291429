import React, { useCallback } from 'react'
import './TaskListFilterCard.scss'
import { StepButton } from '@digitalworkflow/dwtranslateclient'
import { useDispatch, useSelector } from 'react-redux'
import { updateTaskListFilter } from '../../../redux/slices/stepTaskListSlice'
import { RootState } from '../../../redux/types'

interface ITaskListFilterCard {
  step: StepButton
}
const TaskListFilterCard = ({ step }: ITaskListFilterCard) => {
  const dispatch = useDispatch()
  const stepTaskList = useSelector((state: RootState) => state.stepTaskList)

  const handleClick = useCallback(() => {
    dispatch(
      updateTaskListFilter({
        value: stepTaskList.activeFilter.key === step.key_id ? null : step.option_2,
        key: stepTaskList.activeFilter.key === step.key_id ? '' : step.key_id
      })
    )
  }, [step, stepTaskList.activeFilter])

  console.log('stepTaskList', stepTaskList)
  console.log('state', step)

  return (
    <div className='tasklist-filter-card-container' onClick={handleClick}>
      <div className={`task-state  ${stepTaskList.activeFilter.key === step.key_id ? 'selected' : ''} `}>
        <div className='task-step'>
          <p className='task-text'>{step.text}</p>
          <p className='task-count'>{0}</p>
        </div>
      </div>
    </div>
  )
}
export default TaskListFilterCard
